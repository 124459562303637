import { generate } from "@pdfme/generator";
import { barcodes, image, text } from '@pdfme/schemas';
import { DocumentReference, doc as fbDoc, getDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { get, startCase } from "lodash";

import moment from "moment";
import laRojaAncha from "../assets/fonts/La_Roja-AnchaBold.ttf";
import laRojaEstrecha from "../assets/fonts/La_Roja-EstrechaRegular.ttf";
import montserrat from "../assets/fonts/Montserrat-Regular.ttf";
import segoe from "../assets/fonts/SegoeUI.ttf";
import segoeB from "../assets/fonts/SegoeUIBold.ttf";
import paises from "../utils/countries.json";
import fixDate from "./fixDate";

const fonts = { LaRojaEstrecha: laRojaEstrecha, LaRojaAncha: laRojaAncha, SegoeUI: segoe, SegoeUIBold: segoeB, Montserrat: montserrat };

const getFont = (fonts) =>
    Promise.all(Object.values(fonts).map((font) => fetch(font).then((res) => res.arrayBuffer()))).then((buffers) =>
        Object.keys(fonts).reduce(
            (acc, cur, index) =>
                Object.assign(acc, {
                    [cur]: { data: buffers[index], fallback: index === 0 },
                }),
            {}
        )
    );

const replaceAllKeys = (value, obj) => {
    let final = value;
    if (typeof obj === "object") {
        Object.keys(obj).forEach((k) => {
            final = final.replaceAll(`{{${k}}}`, obj[k]);
        });
    }
    return final;
};



const replaceKeys = (key, value, obj) => {
    if (!(typeof obj === "object")) return value;
    if (key.includes("dynamic")) {
        const finalValue = value
            .replaceAll("{{fullUserName}}", (obj?.userName || obj?.name || "") + " " + (obj?.surname || ""))
            .replaceAll("{{UEFAuserName}}", startCase((obj?.userName || obj?.name || "").toLowerCase()))
            .replaceAll("{{UEFAlastName}}", (obj?.surname || "").toUpperCase())
            .replaceAll("{{idNumber}}", obj?.idNumber?.toUpperCase())
            .replaceAll("{{tenantName}}", obj?.tenantName)
            .replaceAll("{{tenant-director}}", get(obj, "tenant-director" || ""))
            .replaceAll("{{tenant-position}}", get(obj, "tenant-position" || ""))
            .replaceAll("{{idType}}", obj?.idType?.toLocaleUpperCase())
            .replaceAll("{{fullBirth}}", moment(fixDate(obj?.birthDate)).add(12, "hours").format("DD.MM.YYYY") + " en " + obj?.birthProvince + ", " + (paises.find(x => x.code === obj?.birthCountry)?.name || obj?.birthCountry || ""))
			.replaceAll("{{birthPlace}}", obj?.birthProvince + ", " + (paises.find(x => x.code === obj?.birthCountry)?.name || obj?.birthCountry || ""))
			.replaceAll("{{birthDate}}", moment(fixDate(obj?.birthDate)).add(12, "hours").format("DD[.]MM[.]YYYY"))
            .replaceAll("{{birthLocation}}", obj?.birthProvince + ", " + (paises.find(x => x.code === obj?.birthCountry)?.name || obj?.birthCountry || ""))
            .replaceAll("{{userName}}", obj?.userName || obj?.name)
            .replaceAll("{{lastName}}", obj?.surname)
            .replaceAll("{{courseName}}", obj?.name)
            .replaceAll("{{courseLocation}}", obj?.city + ", " + obj?.community)
            .replaceAll("{{courseProvince}}", obj?.community)
            .replaceAll("{{courseCity}}", obj?.city)
            .replaceAll("{{courseSeason}}", obj?.season)
            .replaceAll("{{finalGradeDate}}", moment(fixDate(obj?.finalGradeDate)).format("DD.MM.YYYY"))
            .replaceAll("{{courseApprovedtDate}}", moment(fixDate(obj?.finalGradeDate)).format("DD.MM.YYYY"))
            .replaceAll("{{courseStartDate}}", moment(fixDate(obj?.initDate)).format("DD [de] MMMM [del] YYYY"))
            .replaceAll("{{courseEndDate}}", moment(fixDate(obj?.endDate)).format("DD [de] MMMM [del] YYYY"))
            .replaceAll("{{expirationDate}}", moment(fixDate(obj?.expirationDate)).format("DD[.]MM[.]YYYY"))
            .replaceAll("{{rocExpirationDate}}", moment(fixDate(obj?.finalGradeDate)).add(3, "years").format("DD[.]MM[.]YYYY"))
        return replaceAllKeys(finalValue, obj);
    } else {
        if (key === "fullUserName") {
            return (obj?.userName || obj?.name || "") + " " + obj?.surname;
        } else if (key === "finalGradeDate") {
            return moment(fixDate(obj?.finalGradeDate).format("DD.MM.YYYY"))
        } else if (key === "tenantName") {
            return obj?.tenantName || "";
        } else if (key === "idType") {
            return obj?.idType?.toLocaleUpperCase();
        } else if (key === "idNumber") {
            return obj?.idNumber;
        } else if (key === "fullBirth") {
            return (moment(fixDate(obj?.birthDate)).add(12, "hours").format("DD [de] MMMM [del] YYYY") + " en " + (obj?.birthProvince || "") + ", " + (paises.find(x => x.code === obj?.birthCountry)?.name || obj?.birthCountry || ""));
        } else if (key === "fullBirth") {
            return obj?.birthProvince + ", " + (paises.find(x => x.code === obj?.birthCountry)?.name || obj?.birthCountry || "")
        } else if (key === "courseLocation") {
            return obj?.city + ", " + obj?.community;
        } else if (key === "courseProvince") {
            return obj?.province || "";
        } else if (key === "courseCity") {
            return obj?.city || "";
        } else if (key === "courseName") {
            return obj?.name || "";
        } else if (key === "season") {
            return "20" + obj?.season?.slice(0, 2) + "/" + "20" + obj?.season?.slice(2);
        } else if (key === "courseStartDate") {
            return moment(fixDate(obj?.initDate)).format("DD [de] MMMM [del] YYYY");
        } else if (key === "courseApprovedtDate") {
            return moment(fixDate(obj?.finalGradeDate)).format("DD.MM.YYYY")
        } else if (key === "courseEndDate") {
            return moment(fixDate(obj?.endDate)).format("DD [de] MMMM [del] YYYY");
        } else if (key === "qr") {
            return value.replace("{{userId}}", obj?.id).replace("{{tenantId}}", obj?.tenantId).replace("{{courseId}}", obj?.id);
        } else if (key === "rocExpirationDate") {
            return moment(fixDate(obj?.finalGradeDate)).add(3, "years").format("DD[.]MM[.]YYYY")
        } else if (key === "userImage") {
            return obj?.image;
        } else if (key === "tenant-signature") {
            const img = get(obj, "tenant-signature", "")
            if (img) return img
        } else if (key === "tenant-logo") {
            const img = get(obj, "tenant-logo", "")
            if (img) return img
        } else {
            return value;
        }
    }
};

const imageToBase64 = async (url, storage) => getDownloadURL(ref(storage, url))
    .then((url) => {
        const blob = fetch(url).then((r) => r.blob());
        const base64 = blob.then(
            (b) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(b);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                })
        );
        return base64;
    })
    .catch((e) => null)


const findCurrentTemplate = (approvedDate, templates, template) => {
    const d = fixDate(approvedDate.finalGradeDate)
    const current = templates?.find(x => {
        const init = fixDate(x.initDate)
        const end = fixDate(x.endDate)
        return d >= init && d <= end
    })

    return get(current, "template", template) || get(templates[0], "template", template) || template
}

const generatePdf = async (docRef, obj, db) => {

    return new Promise((resolve, reject) => {
        if (!(docRef instanceof DocumentReference)) return Promise.reject(new Error("docRef is not a DocumentReference"));
        if (typeof obj !== "object") return Promise.reject(new Error("obj is not an object"));

        const storage = getStorage();

        try {
            getDoc(docRef).then(async (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    const template = findCurrentTemplate(obj, data.templates, data.template)

                    if (template) {
                        const fileRef = ref(storage, template);

                        const font = await getFont(fonts);

                        const userImage = obj.image ? await imageToBase64(obj.image, storage) : null

                        const tenantData = obj.tenantId ? await getDoc(fbDoc(db, `tenants/${obj.tenantId}`)).then(res => {
                            if (res.exists()) {
                                const newObj = Object.keys(res.data())
                                return newObj.reduce((acc, cur) => ({ ...acc, [`tenant-${cur}`]: res.data()[cur] }), {})
                            } else {
                                return {}
                            }
                        }).catch(err => ({})) : {}

                        const tenantLogo = get(tenantData, "tenant-customization.mainLogo", null) ? await imageToBase64(get(tenantData, "tenant-customization.mainLogo", null), storage) : null
                        const tenantSignature = get(tenantData, "tenant-signature", null) ? await imageToBase64(get(tenantData, "tenant-signature", null), storage) : null


                        getDownloadURL(fileRef).then((url) => {
                            fetch(url)
                                .then((res) => res.json())
                                .then((d) => {
                                    const sample = d.sampledata[0];
                                    const inputs = [
                                        Object.keys(sample).reduce(
                                            (acc, cur) => ({
                                                ...acc,
                                                [cur]: replaceKeys(cur, sample[cur], {
                                                    ...obj,
                                                    image: userImage,
                                                    ...tenantData,
                                                    ["tenant-logo"]: tenantLogo,
                                                    ["tenant-signature"]: tenantSignature,
                                                }),
                                            }),
                                            {}
                                        ),
                                    ];

                                    generate({
                                        template: d,
                                        inputs,
                                        plugins: {
                                            text,
                                            image,
                                            qrcode: barcodes.qrcode,
                                        },
                                        options: {
                                            font
                                        }
                                    }).then((pdf) => {
                                        // Browser
                                        const blob = new Blob([pdf.buffer], { type: "application/pdf" });
                                        window.open(URL.createObjectURL(blob));
                                        resolve(URL.createObjectURL(blob));
                                    });
                                });
                        });
                    }
                }
            });
        } catch (err) {
            console.log(err);
            reject(err);
        }
    })
};

export default generatePdf;
