import { useNavigation, useRoute } from '@react-navigation/native';
import { arrayUnion, collection, deleteDoc, doc, getDoc, getFirestore, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import React, { FC, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, ScrollView, useWindowDimensions, View } from 'react-native';
import AuthContext from '../../context/AuthContext';

import { useFirestoreDocument, useFirestoreQuery } from '@react-query-firebase/firestore';
import { Timeline, TimelineAction, TimelineRow, TimelineState } from '@xzdarcy/react-timeline-editor';

import { Ionicons } from '@expo/vector-icons';
import chroma from "chroma-js";
import { deleteObject, getDownloadURL, getStorage, ref, ref as StorageRef, uploadBytes, uploadString } from 'firebase/storage';
import { clamp, flatMap, get, groupBy, orderBy } from 'lodash';
import { v4 } from "uuid";
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import StyledText from '../../components/common/StyledText';
import TimelinePlayer from '../../components/playerEditor/player';
import VideoBoard from './VideoBoard';

// import { FFmpeg } from '@ffmpeg/ffmpeg';
import { FFmpeg } from "@ffmpeg/ffmpeg";

import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { useForm } from 'react-hook-form';



async function isBase64UrlImage(base64String: string) {
    let image = new Image()
    image.src = base64String
    return await (new Promise((resolve) => {
        image.onload = function () {
            if (image.height === 0 || image.width === 0) {
                resolve(false);
                return;
            }
            resolve(true)
        }
        image.onerror = () => {
            resolve(false)
        }
    }))
}


const VideoExercise = () => {


    const { userData } = useContext(AuthContext)
    const { id } = useRoute().params as any
    const db = getFirestore()
    const navigation = useNavigation()
    const storage = getStorage()

    const [loaded, setLoaded] = useState(false);
    const [rendering, setRendering] = useState(false);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState({ stage: '', progress: 0 });
    const ffmpegRef = useRef(new FFmpeg());

    const messageRef = useRef(null);

    const videoPreviewRef = useRef(null)

    // const [isLoading, setIsLoading] = React.useState(false)
    const [viewBoard, setViewBoard] = React.useState(false)
    const progressRef = useRef(null)
    const relativeDuration = useRef(0)


    const screen = useWindowDimensions()

    const [setSelected, setSetSelected] = React.useState(null)
    const [eventMenu, setEventMenu] = React.useState(false)
    const [selectedEvent, setSelectedEvent] = React.useState(null)
    const [selectedDraw, setSelectedDraw] = React.useState(null)
    const [loadingDraw, setLoadingDraw] = React.useState(false)
    const [zoom, setZoom] = useState(-2.5)


    // Add cache references to track loaded assets
    const cachedAssets = useRef({
        inputVideo: false,
        blackClip: false,
        font: false,
        overlays: {}
    });

    const load = async () => {
        try {
            setLoaded(false);
            const ffmpeg = ffmpegRef.current;

            // Enable logging to help diagnose issues
            // ffmpeg.on("log", ({ message }) => {
            //     console.log("FFmpeg Log:", message);
            // });

            // Use a CDN that's known to work well with CORS
            const baseURL = 'https://cdn.jsdelivr.net/npm/@ffmpeg/core@0.12.6/dist/umd';

            if (ffmpeg.loaded) {
                return;
            }

            try {

                // Load FFmpeg with direct URLs - simpler approach
                await ffmpeg.load({
                    coreURL: `${baseURL}/ffmpeg-core.js`,
                    wasmURL: `${baseURL}/ffmpeg-core.wasm`,
                    workerURL: `${baseURL}/ffmpeg-core.worker.js`,
                });

            } catch (error) {
                // Try with toBlobURL as a fallback
                try {
                    // Create blob URLs for the FFmpeg core files
                    const coreURL = await toBlobURL(
                        `${baseURL}/ffmpeg-core.js`,
                        'text/javascript'
                    );

                    const wasmURL = await toBlobURL(
                        `${baseURL}/ffmpeg-core.wasm`,
                        'application/wasm'
                    );

                    const workerURL = await toBlobURL(
                        `${baseURL}/ffmpeg-core.worker.js`,
                        'text/javascript'
                    );

                    // Load FFmpeg with the blob URLs
                    await ffmpeg.load({
                        coreURL,
                        wasmURL,
                        workerURL
                    });

                } catch (blobError) {
                    throw blobError;
                }
            }

            setLoaded(true);
        } catch (error) {
            console.error("Unexpected error during FFmpeg initialization:", error);
            alert("An unexpected error occurred while initializing video processing. Please try again later.");
        } finally {
            setLoaded(true);
        }
    };


    useEffect(() => {
        load()
        return () => {
            if (ffmpegRef?.current?.loaded) {
                clearFFmpegCache();
            }
        }
    }, [])


    const docRef = doc(db, `users/${userData.id}/videoExercises/${id}`)

    const { data, isLoading } = useFirestoreDocument(["videoExercises", userData.id, { id }], docRef, {
        subscribe: true
    }, {
        select: (d) => ({ ...d.data() as { draws: any[], events: any[] }, ref: d.ref }),
    })

    const eventsRef = collection(db, `users/${userData.id}/eventSets`)

    const { data: events } = useFirestoreQuery(["eventSets", userData.id], eventsRef, {
    }, {
        select: (d) => d.docs.map(x => ({ ...x.data(), id: x.id, ref: x.ref })).sort((a, b) => {
            const dateA = a?.updatedAt?.toDate()
            const dateB = b?.updatedAt?.toDate()
            return dateB.getTime() - dateA.getTime()
        })
    })

    const videoRef = useRef(null)
    const timelineState = useRef<TimelineState>();
    const scrollViewRef = useRef<HTMLDivElement>(null)
    const autoScrollWhenPlay = useRef<boolean>(true);

    const loadCachedAsset = async (name, url) => {
        const ffmpeg = ffmpegRef.current;
        try {
            await ffmpeg.readFile(name)
            return true
        } catch (err) {
            try {
                await ffmpeg.writeFile(name, await fetchFile(url));
                return true
            } catch (err) {
                throw err
            }
        }
    }

    const generateVideo = async (cuts, overlays, w = 1280, h = 720, name) => {
        const width = w > 1280 ? 1280 : w
        const height = h > 720 ? 720 : h
        try {

            const ffmpeg = ffmpegRef.current;
            if (!ffmpeg.loaded) {
                await ffmpeg.load();
            }

            // Get the original video duration.
            const originalVideoDuration = videoRef?.current?.duration();
            // Filter and order overlays that fall within the cuts.
            const validOverlays = orderBy(overlays, ["start"], "asc")
                .filter(x => cuts.some(y => y.start <= x.start && y.end >= x.start));

            // Get overlay URIs (ensure your Firebase Storage CORS is properly configured).
            const overlayUris = await Promise.all(
                validOverlays.map(async (overlay) => {
                    const uri = await getDownloadURL(ref(storage, overlay.uri))
                        .catch(err => null);
                    return uri;
                })
            );

            // Calculate the clip duration (summing cuts and overlay durations).
            const clipDuration = cuts.reduce((acc, cur) => acc + (cur.end - cur.start), 0) +
                validOverlays.reduce((acc, cur) => acc + (cur.duration || 5), 0);

            relativeDuration.current = clipDuration / originalVideoDuration;

            let concat = [];
            let finalCuts = [];
            let overlayTimes = [];
            let startTime = 0;

            orderBy(cuts, ["start"], "asc").forEach((cut) => {
                const hasOverlay = validOverlays.filter(x => x.start >= cut.start && x.start <= cut.end);

                if (hasOverlay?.length > 0) {
                    // First part of the cut (before the first overlay).
                    finalCuts.push({
                        ...cut,
                        start: cut.start,
                        end: hasOverlay[0].start
                    });

                    hasOverlay.forEach((overlay, index, arr) => {
                        if (hasOverlay.length === 1) {
                            // Only one overlay.
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            });
                        } else if (index === 0) {
                            // First overlay.
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            });
                            finalCuts.push({
                                ...cut,
                                start: overlay.start,
                                end: arr[index + 1].start
                            });
                        } else if (index === arr.length - 1) {
                            // Last overlay.
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            });
                        } else {
                            // Middle overlays.
                            finalCuts.push({
                                ...overlay,
                                overlay: true,
                                start: 0,
                                end: overlay.duration
                            });
                            finalCuts.push({
                                ...cut,
                                start: overlay.start,
                                end: arr[index + 1].start
                            });
                        }

                        overlayTimes.push({
                            ...overlay,
                            start: startTime + overlay.start - cut.start,
                            end: startTime + overlay.start - cut.start + overlay.duration
                        });

                        startTime = startTime + (cut.end - cut.start) + overlay.duration;
                    });

                    // Last part of the cut (after overlays).
                    finalCuts.push({
                        ...cut,
                        start: hasOverlay[hasOverlay.length - 1].start,
                        end: cut.end
                    });
                } else {
                    finalCuts.push(cut);
                    startTime = startTime + (cut.end - cut.start);
                }
            });

            // Use the remote video's URL from videoRef.current.
            const videoUrl = videoRef?.current?.src;

            // Load assets with caching
            const loadAssets = async () => {

                const assetPromises = [];

                setLoadingAssets(true);

                setLoadingProgress({ stage: 'Preparando medios...', progress: 10 });

                assetPromises.push(
                    loadCachedAsset('input.mp4', videoUrl),
                    loadCachedAsset('black.mp4', "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Fcamelot%2Fblack.mp4?alt=media&token=85ddaab2-e210-4c5a-8ce6-808231ce2879"),
                    loadCachedAsset('font.woff', "https://cdn.jsdelivr.net/npm/@fontsource/roboto@4.5.8/files/roboto-latin-400-normal.woff")
                )

                if (overlayUris.length > 0) {
                    orderBy(overlayUris, ["start"], "asc").map(async (uri, index) => {
                        assetPromises.push(
                            loadCachedAsset(`overlay${index}.jpg`, uri)
                        )
                    });
                }

                await Promise.all(assetPromises);

                setLoadingProgress({ stage: 'Preparando medios...', progress: 100 });
            };

            // Load all required assets
            await loadAssets();

            // Verify audio presence in the input file
            const hasAudio = await verifyAudioInFile(ffmpeg, 'input.mp4');

            ffmpeg.on("progress", ({ progress, time }) => {
                if (progressRef.current) {
                    const p = (progress > 1 ? 0 : progress)
                    progressRef.current.style.width = `${(p / relativeDuration?.current) * 100}%`;
                }
            });

            // Simplify the approach - use a video-only filter that works regardless of audio presence
            let overlayIndex = 0;
            const videoTrims = finalCuts.map((cut, index) => {
                if (cut.overlay) {
                    const overlayPosition = overlayIndex + 1;
                    concat.push(`[v${overlayPosition}o]`);
                    // For overlays, create silent audio to maintain sync
                    let str = `[0:v]trim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)},setpts=PTS-STARTPTS[v${index}];` +
                        `[${overlayPosition}:v]scale=${width}:${height}[o${overlayPosition}];` +
                        `[v${index}][o${overlayPosition}]overlay=0:0:enable='between(t,0,${cut.end})'[v${overlayPosition}o]`;

                    // Extract audio for this segment if it exists
                    if (hasAudio) {
                        // Use the detected audio stream mapping with precise trimming
                        str += `;[0:a]atrim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)},asetpts=PTS-STARTPTS,volume=0[a${index}]`;
                        concat.push(`[a${index}]`);
                    }

                    overlayIndex++;
                    return str;
                } else {
                    concat.push(`[v${index}]`);
                    let str = `[0:v]trim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)},setpts=PTS-STARTPTS[v${index}]`;

                    // Extract audio for this segment if it exists
                    if (hasAudio) {
                        // Use the detected audio stream mapping with precise trimming
                        str += `;[0:a]atrim=start=${Math.fround(cut.start)}:end=${Math.fround(cut.end)},asetpts=PTS-STARTPTS[a${index}]`;
                        concat.push(`[a${index}]`);
                    }

                    if (cut?.id) {
                        // Use a more robust drawtext configuration
                        // If font.woff fails, it will use a default font
                        str += `;[v${index}]drawtext=text='${cut.name || ""}':x=10:y=(h-text_h)-10:fontfile=font.woff:fontsize=30:fontcolor=${chroma(cut.color).luminance() > 0.5 ? "black" : "white"
                            }:enable='between(t,${0},${5})':box=1:boxcolor=${cut.color}:boxborderw=10[v${index}]`;
                    }
                    return str;
                }
            }).join(';');

            // Separate video and audio streams for concat
            const videoStreams = concat.filter(stream => stream.includes('v'));
            const audioStreams = concat.filter(stream => stream.includes('a'));

            // Create separate concat filters for video and audio
            let concatFilter = '';
            if (hasAudio && audioStreams.length > 0) {
                concatFilter = `${videoStreams.join('')}concat=n=${videoStreams.length}:v=1:a=0[vout];` +
                    `${audioStreams.join('')}concat=n=${audioStreams.length}:v=0:a=1[aout]`;
            } else {
                concatFilter = `${videoStreams.join('')}concat=n=${videoStreams.length}:v=1[vout]`;
            }

            // Create a command that works with or without audio
            const command = [
                '-i', "input.mp4",
                ...(overlayTimes.length > 0 ? overlayTimes.map((x, i) => ['-i', `overlay${i}.jpg`]).flat() : []),
                '-filter_complex', `${videoTrims};${concatFilter}`,
                '-map', '[vout]',
            ];

            // Add audio mapping only if audio exists
            if (hasAudio) {
                command.push('-map', '[aout]');
                // Use detected audio parameters if available
                const audioBitrate = '128k'; // Default bitrate
                const audioSampleRateValue = '44100';
                command.push(
                    '-c:a', 'aac',
                    '-b:a', audioBitrate,
                    '-ar', audioSampleRateValue,
                    '-ac', '2',
                    '-strict', 'experimental'
                );
            } else {
                command.push('-an'); // No audio
            }

            // Add remaining parameters
            command.push(
                '-c:v', 'libx264',
                "-threads", "4",
                "-preset", "superfast",
                // '-movflags', '+faststart',
                'output.mp4'
            );

            setLoadingAssets(false);
            setRendering(true);

            try {
                await ffmpeg.exec(command);
            } catch (execError) {
                console.error("FFmpeg execution error:", execError);
                throw execError; // Re-throw if it's not a known error
            }

            const data = await ffmpeg.readFile('output.mp4');

            setRendering(false);
            progressRef.current.style.width = `0%`;

            // Create a download link for the generated video.
            const a = document.createElement('a');
            a.download = (name || "Resumen") + '.mp4';
            // Handle different types of data returned by FFmpeg
            let blobData: Uint8Array;
            if (data && data instanceof Uint8Array) {
                blobData = data;
            } else if (data && typeof data === 'object' && 'buffer' in data) {
                blobData = new Uint8Array(data.buffer);
            } else if (data && typeof data === 'string') {
                blobData = new TextEncoder().encode(data);
            } else {
                blobData = new Uint8Array();
            }
            a.href = URL.createObjectURL(new Blob([blobData], { type: 'video/mp4' }));
            a.click();

            alert("Video generado correctamente");

        } catch (err) {
            console.log(err);
            progressRef.current.style.width = `0%`;
            alert("Ocurrió un evento al generar el video, asegurate de estar en un navegador compatible y que los dibujos no se solapen entre vaios eventos");
        } finally {
            const ffmpeg = ffmpegRef.current;
            setRendering(false);
            setLoadingAssets(false)
            ffmpeg?.off("progress", () => { });
        }
    };

    // Add a function to clear the cache when needed (e.g., when leaving the page)
    const clearFFmpegCache = useCallback(async () => {
        if (ffmpegRef.current) {
            try {
                const ffmpeg = ffmpegRef.current;

                // Clean up input file
                if (cachedAssets.current.inputVideo) {
                    await ffmpeg.deleteFile('input.mp4').catch(() => { });
                }

                // Clean up black clip
                if (cachedAssets.current.blackClip) {
                    await ffmpeg.deleteFile('black.mp4').catch(() => { });
                }

                // Clean up font
                if (cachedAssets.current.font) {
                    await ffmpeg.deleteFile('font.woff').catch(() => { });
                }

                // Clean up overlays
                Object.keys(cachedAssets.current.overlays).forEach(async (key) => {
                    await ffmpeg.deleteFile(key + '.jpg').catch(() => { });
                });

                // Reset cache state
                cachedAssets.current = {
                    inputVideo: false,
                    blackClip: false,
                    font: false,
                    overlays: {}
                };

                // Clean up FFmpeg memory
                cleanupFFmpegMemory(ffmpeg);

            } catch (e) {
                console.warn("Error clearing FFmpeg cache:", e);
            }
        }
    }, []);


    const downloadDraw = async (draw) => {
        try {

            const uri = await getDownloadURL(StorageRef(storage, draw.thumbnail))
            fetch(uri)
                .then(res => res.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    a.download = draw.id + '.jpg';
                    a.href = URL.createObjectURL(blob);
                    a.click();
                })
                .catch(err => {
                    console.log(err)
                    alert("Ha ocurrido un error al descargar el dibujo")
                })
        } catch (err) {
            console.log(err)
        }
    }


    const createDraw = async (retry?: boolean) => {
        try {

            setLoadingDraw(true)
            setSelectedDraw(null)
            setSelectedEvent(null)
            videoRef.current.pause()

            const time = videoRef?.current?.currentTime()

            const video = videoRef?.current?.videoRef.current
            const canvas = document.createElement('canvas');

            canvas.width = videoRef?.current?.videoWidth;
            canvas.height = videoRef?.current?.videoHeight;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailBase64 = canvas.toDataURL('image/png');

            const isValid = await isBase64UrlImage(thumbnailBase64)
            if (!thumbnailBase64 || !isValid) {
                if (retry) {
                    setLoadingDraw(false)
                    return alert("Ha ocurrido un error al generar el dibujo")
                }
                setTimeout(() => {
                    createDraw(true)
                }, 250)

                return null
            }

            const drawId = v4()
            const thumbnail = `users/${userData.id}/videoExercises/${id}/draws/base-${drawId}.png`
            const image = await uploadString(ref(storage, thumbnail), thumbnailBase64, "data_url").catch(err => null)

            if (image) {
                setDoc(doc(db, `users/${userData.id}/videoExercises/${id}/draws/${drawId}`), {
                    id: drawId,
                    data: null,
                    thumbnail: "",
                    base: thumbnail,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                }).then(res => {
                    updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                        draws: arrayUnion({
                            id: drawId,
                            uri: "",
                            time: time,
                            start: time,
                            end: time,
                            duration: 5,
                            flexible: false,
                            movable: false,
                        })
                    })
                        .then(res => {
                            setSelectedDraw(drawId)
                            selectDraw({
                                id: drawId,
                                uri: "",
                                time: time,
                                start: time,
                                end: time,
                                duration: 5,
                                flexible: false,
                                movable: false,
                            })
                            setLoadingDraw(false)
                        })
                        .catch(err => {
                            console.log(err)
                            alert("Ha ocurrido un error al guardar el dibujo")
                            videoRef.current.pause()
                            setLoadingDraw(false)
                        })
                }).catch(err => {
                    console.log(err)
                    alert("Ha ocurrido un error al guardar el dibujo")
                    videoRef.current.pause()
                    setLoadingDraw(false)
                })
            } else {
                alert("Ha ocurrido un error al guardar el dibujo")
                return
            }
        } catch (err) {
            console.log(err)
            alert("Ha ocurrido un error al guardar el dibujo")
            videoRef.current.pause()
            setLoadingDraw(false)
        }

    }

    const deleteDraw = async () => {

        videoRef.current.pause()

        const p = confirm("¿Estás seguro de que quieres eliminar este dibujo? Esta acción no se puede deshacer")

        if (p) {
            await updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                draws: data?.draws?.filter(x => x.id !== selectedDraw)
            }).then(res => {

            }).catch(err => {
                alert("Ha ocurrido un error al guardar el dibujo")
            })

            await deleteObject(ref(storage, `users/${userData.id}/videoExercises/${id}/draws/${selectedDraw}.png`)).catch(err => null)
            await deleteObject(ref(storage, `users/${userData.id}/videoExercises/${id}/draws/base-${selectedDraw}.png`)).catch(err => null)
            await deleteObject(ref(storage, `users/${userData.id}/videoExercises/${id}/draws/store-${selectedDraw}.json`)).catch(err => null)

            await deleteDoc(doc(db, `users/${userData.id}/videoExercises/${id}/draws/${selectedDraw}`)).then(res => {

            }).catch(err => {
                alert("Ha ocurrido un error al eliminar el dibujo")
            })

            setSelectedEvent(undefined)
            setSelectedDraw(undefined)
            alert("Dibujo eliminado correctamente")
        }
    }

    const addEvent = (event) => {
        const time = videoRef?.current?.currentTime()
        const duration = videoRef?.current?.duration()
        updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
            events: arrayUnion({
                id: v4(),
                eventId: event.eventId,
                start: clamp(time - event.timeBefore, 0, duration),
                end: clamp(time + event.timeAfter, 0, duration),
                name: event.name || "",
                abr: event.abr || "",
                color: event.color,
            })
        }).catch(err => {
            alert("Ha ocurrido un error al guardar el evento")
            videoRef?.current?.pause()
        })
    }

    const getDrawData = async (draw) => {
        const uri = `users/${userData.id}/videoExercises/${id}/draws/store-${draw}.json`
        const docRef = StorageRef(storage, uri)
        getDownloadURL(docRef).then(res => {
            fetch(res).then(res => res.json()).then(res => {
                setValue("data", res)
            }).catch(err => {
                console.log(err)
                alert("Ha ocurrido un error al cargar el dibujo")
            })
        }).catch(err => {
            setValue("data", undefined)
            null
        })
    }

    const updateDraw = async (ev) => {
        setValue("data", ev.snap)

        const drawId = selectedDraw
        const uri = `users/${userData.id}/videoExercises/${id}/draws/${drawId}.png`
        const uristore = `users/${userData.id}/videoExercises/${id}/draws/store-${drawId}.json`

        const res = await uploadBytes(ref(storage, uri), ev.svg)
        const store = await uploadString(ref(storage, uristore), JSON.stringify(ev.snap)).catch(err => console.log(err))

        await updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}/draws/${drawId}`), {
            thumbnail: uri,
            updatedAt: serverTimestamp(),
        }).then(res => {
            alert("Ejercicio guardado correctamente")
        }).catch(err => {
            console.log(err)
            alert("Ha ocurrido un error al guardar el ejercicio")
        })

        await updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
            draws: (data?.draws || [])?.map(x => {
                if (x.id === drawId) return { ...x, uri: uri }
                return x
            })
        })

        setViewBoard(false)

    }

    const selectDraw = async (draw) => {
        videoRef.current.pause()
        if (draw) {
            videoRef.current.setCurrentTime(draw.start)

            const drawRef = doc(db, `users/${userData.id}/videoExercises/${id}/draws/${draw.id}`)
            const dataDraw = await getDoc(drawRef)
                .then(res => res.data())
                .catch(err => null)

            const uri = `users/${userData.id}/videoExercises/${id}/draws/store-${draw.id}.json`

            const docRef = StorageRef(storage, uri)
            const drawDataStore = await getDownloadURL(docRef).then(async res => {
                return fetch(res).then(res => res.json()).then(res => {
                    return res
                }).catch(err => {
                    console.log(err)
                    return undefined
                })
            }).catch(err => {
                return undefined
            })


            if (!dataDraw) {
                alert("Ha ocurrido un error al cargar el dibujo")
                return
            }

            const base64 = await getDownloadURL(StorageRef(storage, dataDraw.thumbnail || dataDraw.base)).then(res => {
                return fetch(res).then(res => res.blob()).then(blob => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            resolve(reader.result);
                        };
                    });
                })
            }).catch(err => null)


            setViewBoard({
                id: draw?.id,
                data: drawDataStore,
                thumbnail: base64,
                height: data?.height,
                width: data?.width
            })

        } else {
            alert("Ha ocurrido un error al cargar el dibujo")
        }
    }

    const timelineValues = useMemo(() => {
        return ({
            scaleWidth: 250,
            scale: 10 * Math.abs(zoom),
            startLeft: 20,
        })
    }, [zoom])


    const groupedEvents = useMemo(() => {
        if (!data?.events) return []
        const grouped = groupBy(data?.events, "eventId")
        const final = Object.keys(grouped).map(x => ({ id: x, actions: grouped[x].map(x => ({ ...x, effectId: `${x.id}-${x.color}` })) }))

        return [{ id: "draws", actions: data?.draws || [] }, ...final, { id: "video", actions: [{ id: "video", start: 0, end: data?.duration || 0, name: "Video", color: "#151515", flexible: false, movable: false }] }]
    }, [data])



    const {
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: { data: null }
    });



    if (isLoading || !loaded) return (
        <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
            <ActivityIndicator />
        </View>
    )


    return (
        <>
            {loadingAssets ?
                <Modal>
                    <View style={{ gap: 10, alignItems: "center", padding: 15 }}>
                        <StyledText style={{ textAlign: "center" }}>{loadingProgress.stage}</StyledText>
                        <ActivityIndicator size="large" />
                    </View>
                </Modal>
                : null
            }
            {rendering ?
                <Modal>
                    <View style={{ gap: 10, alignItems: "center", padding: 15 }}>
                        <StyledText style={{ textAlign: "center" }}>Generando video, una vez terminado se descargará automaticamene a tu equipo</StyledText>
                        <View style={{ overflow: "hidden", width: "100%", backgroundColor: "#f3f3f3", height: 15, borderRadius: 5, position: "relative" }}>
                            <View ref={progressRef} style={{ position: "absolute", transition: "all .5s", top: 0, left: 0, height: 15, borderRadius: 5, backgroundColor: "red" }} />
                        </View>
                        <Button
                            height={35}
                            label="Cancelar"
                            onPress={async () => {
                                const ffmpeg = ffmpegRef.current;
                                try {
                                    ffmpeg?.terminate()
                                    setRendering(false);
                                } catch (err) {
                                    console.log(err)
                                }
                            }}
                            icon={undefined}
                            color={undefined}
                            children={undefined}
                            textColor={undefined}
                            title={undefined}
                        />
                    </View>
                </Modal>
                : null
            }
            {viewBoard ?
                <Modal modalContainerStyle={{ height: "100%", width: "100%" }} padding={0} margin={0}>
                    <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: viewBoard ? 100 : -1 }}>
                        <VideoBoard
                            closeCallback={() => setViewBoard(false)}
                            saveCallback={updateDraw}
                            initialData={get(viewBoard, "data", undefined)}
                            element={viewBoard}
                            id={selectedDraw}
                            style={{ flex: 1 }}
                        />

                    </View>
                </Modal>
                :
                null
            }

            {eventMenu ?
                <Modal onClickOutside={() => setEventMenu(false)} visible={eventMenu} onClose={() => setEventMenu(false)} style={{ position: "absolute", top: 50, right: 10, zIndex: 100 }}>
                    <View style={{ padding: 10, backgroundColor: "white", borderRadius: 4, maxWidth: 300, width: "100%" }}>
                        <StyledText style={{ fontSize: 16, fontFamily: "TitleWide", marginBottom: 10 }}>Botoneras</StyledText>
                        {events.length === 0 ?
                            <View style={{ paddingVertical: 50, alignItems: "center", justifyContent: "center" }}>
                                <StyledText style={{ fontSize: 12, fontFamily: "TitleWide", marginBottom: 10 }}>No tienes botones creados</StyledText>
                            </View>
                            :
                            events?.map((ev, i) => (
                                <Button
                                    key={i}
                                    style={{ padding: 10, borderRadius: 4, marginBottom: 10, boxShadow: "0px 2px 5px rgba(0,0,0,0.1)" }}
                                    height={35}
                                    label={ev.name}
                                    onPress={() => {
                                        setSetSelected(ev)
                                        setEventMenu(false)
                                    }}
                                    round={5}
                                    color={"white"}
                                />
                            ))}
                    </View>
                </Modal>
                : null
            }

            <View style={{ backgroundColor: "rgba(0,0,0,.96)", flex: 1 }}>
                <View style={{ flex: 3, zIndex: 10, minHeight: 180 }}>
                    <View style={{ position: "absolute", top: 10, left: 10, zIndex: 100, gap: 5 }}>
                        <Button
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            onPress={() => navigation.goBack()}
                            round={5}
                            icon="arrow-back"
                            title="Volver Atrás"
                        />
                        <Button
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            onPress={() => setEventMenu(!eventMenu)}
                            round={5}
                            color={"white"}
                            icon="stopwatch"
                            title="Seleccionar Botonera"
                        />
                        <Button
                            disabled={loadingDraw || videoRef?.current?.currentTime() === 0 || selectedDraw}
                            loading={loadingDraw}
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            onPress={() => createDraw(false)}
                            round={5}
                            color={"white"}
                            icon="brush"
                            title="Añadir Dibujo en el segundo actual"
                        />

                        <Button
                            style={{ padding: 10, borderRadius: 4 }}
                            width={"35px"}
                            height={35}
                            paddingHorizontal={0}
                            disabled={data?.draws?.length === 0 && data?.events?.length === 0}
                            onPress={() => {
                                if (data?.events?.length === 0) {
                                    generateVideo([{ start: 0, end: videoRef?.current?.duration() }], data.draws, data?.width, data?.height, "resumen-de-eventos")
                                } else {
                                    generateVideo(data.events, data.draws, data?.width, data?.height, "resumen-de-eventos")
                                }
                            }}
                            round={5}
                            color={"white"}
                            icon="download"
                            title="Renderizar Eventos"
                        />

                    </View>
                    {setSelected ?
                        <View
                            style={{ zIndex: 100, position: "absolute", top: 0, bottom: 0, right: 0, backgroundColor: "rgba(0,0,0,.2)" }}
                        >
                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                contentContainerStyle={{ padding: 10, zIndex: 100, gap: 5, alignItems: "flex-end", justifyContent: "center" }}
                                style={{ flex: 1 }}
                            >
                                {setSelected?.events?.map((ev, i) => (
                                    <Button
                                        height={35}
                                        paddingHorizontal={10}
                                        round={5}
                                        key={i}
                                        color={ev.color}
                                        label={ev.name}
                                        onPress={() => addEvent(ev)}
                                        icon={undefined}
                                        children={undefined}
                                        textColor={undefined} />
                                ))}
                            </ScrollView>

                        </View>
                        :
                        null
                    }
                    {selectedDraw ? <ImageOverlay videoId={id} drawId={selectedDraw} /> : null}
                    <VideoViewer timelineState={timelineState} ref={videoRef} uri={data?.media} draws={data?.draws || []} style={{ height: "100%", objectFit: "contain" }} />

                </View>

                <TimelinePlayer
                    selectedDraw={selectedDraw}
                    deleteDrawCallback={deleteDraw}
                    editDrawCallback={() => selectDraw(data?.draws.find(x => x.id === selectedDraw))}
                    downloadDrawCallback={() => {
                        const draw = data?.draws.find(x => x.id === selectedDraw)
                        if (draw) {
                            downloadDraw(draw)
                        }
                    }}
                    deleteCallback={() => {
                        videoRef.current.pause()
                        const p = confirm("¿Estás seguro de que quieres eliminar este dibujo? Esta acción no se puede deshacer")
                        if (p) {
                            updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                                events: data?.events?.filter(x => x.id !== selectedEvent)
                            }).then(res => {
                                setSelectedEvent(null)
                                setSelectedDraw(null)
                            }).catch(err => {
                                alert("Ha ocurrido un error al guardar el ejercicio")
                            })
                        }
                    }}
                    renderCallback={() => {
                        const ev = data.events?.find(x => x.id === selectedEvent)
                        if (ev) {
                            generateVideo([ev], data.draws, data?.width, data?.height, ev?.name)
                        } else {
                            alert("No se ha encontrado el evento que exportar")
                        }
                    }}
                    moveCallback={(time, selected) => {
                        if (selected) {
                            setSelectedDraw(null)
                        }
                    }}
                    selectedEvent={selectedEvent}
                    timelineValues={timelineValues}
                    timelineState={timelineState}
                    autoScrollWhenPlay={autoScrollWhenPlay}
                    videoRef={videoRef}
                />

                <View style={{ flexDirection: "row", flex: 1, minHeight: 250 }}>
                    <View style={{ width: "20%", maxWidth: 300, height: "100%" }}>
                        <View style={{ height: 40, gap: 5, alignItems: "center", justifyContent: "space-between", paddingHorizontal: 10, flexDirection: "row" }}>
                            <Ionicons name="remove" size={18} color="white" />
                            <input className='zoom' style={{ width: "100%" }} value={zoom} type="range" min={-5} max={-.1} step={.1} onChange={(ev) => setZoom(parseFloat(ev.target.value))} />
                            <Ionicons name="add" size={18} color="white" />
                        </View>
                        <div
                            ref={scrollViewRef}
                            className='timeline-scroll'
                            style={{ borderTopColor: "rgba(256,256,256,.2)", borderTopWidth: 1, overflow: "auto" }}
                            onScroll={(e) => {
                                const target = e.target as HTMLDivElement;
                                timelineState.current.setScrollTop(target.scrollTop);
                            }}
                        >
                            {groupedEvents.map((item, index) => {
                                return (
                                    <View style={{ height: 40 }} key={item.id}>
                                        <View style={{ height: "100%", paddingLeft: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottomWidth: 1, borderColor: "rgba(256,256,256,.2)", }}>

                                            <StyledText numberOfLines={1} style={{ color: "white" }}>{`${item.id === "draws" ? "Dibujos" : item.actions[0]?.name || ""}`}</StyledText>
                                            {(index >= 1 && item.id !== "video") ? <Button
                                                icon="download"
                                                iconSize={12}
                                                paddingHorizontal={5}
                                                color="black"
                                                width={"25px"}
                                                height={20}
                                                round={2}
                                                title="Renderizar Eventos"
                                                onPress={() => {
                                                    generateVideo(item.actions, data.draws, data?.width, data?.height, item.id)
                                                }}
                                            /> : null}
                                        </View>
                                    </View>
                                );
                            })}
                        </div>
                    </View>
                    <Timeline
                        style={{ height: "100%", width: "100%" }}
                        editorData={groupedEvents}
                        effects={undefined}
                        onCursorDrag={(ev) => videoRef?.current?.setCurrentTime(ev)}
                        rowHeight={40}
                        onCursorDragStart={() => videoRef?.current?.pause()}
                        autoScroll={true}
                        ref={timelineState}
                        scale={timelineValues.scale}
                        startLeft={timelineValues.startLeft}
                        scaleWidth={timelineValues.scaleWidth}
                        scaleSplitCount={10}
                        onChange={(ev) => {
                            updateDoc(doc(db, `users/${userData.id}/videoExercises/${id}`), {
                                events: flatMap(ev.filter(x => x.id !== "draws" && x.id !== "video"), x => x.actions)
                            }).then(res => {
                            }).catch(err => {
                                alert("Ha ocurrido un error al guardar el ejercicio")
                                videoRef.current.pause()
                            })
                        }}
                        onClickAction={(e, { action, row, time }) => {
                            if (row.id === "video") {
                                return
                            }
                            videoRef.current?.setCurrentTime(action.start)
                            timelineState?.current?.setTime(action.start)
                            if (row.id === "draws") {
                                setSelectedEvent(null)
                                if (action.id === selectedDraw) {
                                    setSelectedDraw(null)
                                } else {
                                    setSelectedDraw(action.id)
                                }
                            } else {
                                setSelectedDraw(null)
                                if (action.id === selectedEvent) {
                                    setSelectedEvent(null)
                                } else {
                                    setSelectedEvent(action.id)
                                }
                            }
                        }}
                        getScaleRender={(scale) => <CustomScale scale={scale} />}
                        onClickTimeArea={(ev) => {
                            videoRef?.current?.pause()
                            videoRef?.current?.setCurrentTime(ev)
                        }}
                        onActionMoving={(ev) => {
                            videoRef?.current?.setCurrentTime(ev.start)
                            timelineState?.current?.setTime(ev.start)
                        }}
                        onActionResizing={(ev) => {
                            if (ev.dir === "left") {
                                videoRef?.current?.setCurrentTime(ev.start)
                                timelineState?.current?.setTime(ev.start)
                            } else {
                                videoRef?.current?.setCurrentTime(ev.end)
                                timelineState?.current?.setTime(ev.end)
                            }
                        }}
                        onScroll={({ scrollTop }) => {
                            scrollViewRef.current.scrollTop = scrollTop;
                        }}
                        getActionRender={(action, row) => {
                            if (row.id === "draws") {
                                return <CustomRender1 action={action} row={row} selected={action.id === selectedDraw} />
                            } else {
                                return <CustomRender0 action={action} row={row} selected={action.id === selectedEvent} />
                            }
                        }}
                    />

                </View>
            </View >
        </>
    );
}

const CustomScale = (props: { scale: number }) => {
    const { scale } = props;
    const min = parseInt(scale / 60 + '');
    const second = (scale % 60 + '').padStart(2, '0');
    return <>{`${min}:${second}`}</>
}

export default VideoExercise;




export const CustomRender1: FC<{ action: TimelineAction; row: TimelineRow, selected: boolean }> =
    ({ action, row, selected }) => {
        return (
            <View style={{ overflow: "hidden", width: 40, height: 40, marginLeft: -20, alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <Ionicons name="brush" size={20} color={selected ? "orange" : "white"} />
            </View>
        );
    };

export const CustomRender0: FC<{ action: TimelineAction; row: TimelineRow, selected: boolean }> =
    ({ action, row, selected }) => {
        const textColor = useMemo(() => chroma(action.color).luminance() > 0.5 ? "black" : "white", [action.color])
        return (
            <View style={{ overflow: "hidden", marginHorizontal: 8, marginVertical: 1, flex: 1, height: 38, borderWidth: 2, borderColor: selected ? "orange" : "transparent", alignItems: "center", justifyContent: "center", backgroundColor: action.color }}>
                <StyledText style={{ pointerEvents: "none", fontSize: 14, color: textColor }}>{`${secondsToHMS(action.end - action.start)}`}</StyledText>
            </View>
        );
    };


const ImageOverlay = ({ videoId, drawId }) => {
    const { userData } = useContext(AuthContext)
    const db = getFirestore()
    const storage = getStorage()
    const [signed, setSigned] = useState(null)
    const { data } = useFirestoreDocument(["image", videoId, drawId], doc(db, `users/${userData?.id}/videoExercises/${videoId}/draws/${drawId}`), {
        subscribe: true
    }, {
        select: (data) => {
            if (!data) return null
            return {
                ...data.data(),
            }
        }
    })

    useEffect(() => {
        if (data?.thumbnail || data?.base) {
            const gsReference = StorageRef(storage, data?.thumbnail || data?.base);
            getDownloadURL(gsReference).then((url) => {
                setSigned(url)
            }).catch((error) => {
                console.log(error)
            });
        }

    }, [data])

    if (!signed) return null
    return (
        <img src={signed} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 10, width: "100%", height: "100%", objectFit: "contain" }} />
    )
}


const VideoViewer = forwardRef(({ uri, style, timelineState }: { uri: string, style: any, timelineState: React.RefObject<TimelineState> }, ref) => {
    const [signed, setSigned] = useState(null)
    const videoRef = useRef(null)
    const intervalRef = useRef(null)

    useEffect(() => {
        const storage = getStorage()
        if (uri) {
            const gsReference = StorageRef(storage, uri);
            getDownloadURL(gsReference).then((url) => {
                setSigned(url)
            }).catch((error) => {
                console.log(error)
            });
        }
    }, [uri])


    useImperativeHandle(ref, () => ({
        duration: () => {
            return videoRef.current.duration
        },
        pause: () => {
            videoRef.current.pause()
            timelineState?.current?.pause()
            clearInterval(intervalRef.current)
            intervalRef.current = null
        },
        play: () => {
            intervalRef.current = setInterval(function () {
                timelineState?.current?.setTime(videoRef.current.currentTime)
            }, 66);
            videoRef.current.play()
            timelineState?.current?.play({ autoEnd: true })
        },
        setCurrentTime: (time) => {
            videoRef.current.currentTime = time
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        },
        playbackRate: (rate) => {
            videoRef.current.playbackRate = rate
        },
        currentTime: () => {
            return videoRef.current.currentTime
        },
        videoRef: videoRef,
        videoWidth: videoRef.current.videoWidth,
        videoHeight: videoRef.current.videoHeight,
        src: signed
    }));

    const handleEnd = (ev) => {
        timelineState?.current?.setTime(0)
        timelineState?.current?.setScrollLeft(0)
        clearInterval(intervalRef.current)
        intervalRef.current = null
    }


    const setupVideoElement = (video) => {
        if (video) {
            videoRef.current = video
            video.addEventListener('ended', handleEnd);
        }
    };

    return (
        <video playsInline={true} webkit-playsinline={"true"} preload="metadata" crossOrigin="anonymous" style={style} ref={setupVideoElement} src={signed} controls={false} />
    )
})

function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    if (hours > 0) return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
}

function cleanupFFmpegMemory(ffmpeg) {
    try {
        // List all files in the virtual filesystem
        const files = ffmpeg.listDir('/');

        // Delete all files except core FFmpeg files
        for (const file of files) {
            if (!file.startsWith('.')) {
                try {
                    ffmpeg.deleteFile(file);
                } catch (e) {
                    console.warn(`Could not remove file ${file}:`, e);
                }
            }
        }

        // Force garbage collection if possible
        if (typeof global !== 'undefined' && global.gc) {
            global.gc();
        }
    } catch (error) {
        console.warn("Error cleaning up FFmpeg memory:", error);
    }
}

async function preTrimInputVideo(ffmpeg, cuts) {
    // Only pre-trim if we have a reasonable number of cuts and they span a small portion of the video
    if (cuts.length === 0) return;

    try {
        // Find the earliest start and latest end times
        let earliestStart = Infinity;
        let latestEnd = 0;

        for (const cut of cuts) {
            earliestStart = Math.min(earliestStart, cut.start);
            latestEnd = Math.max(latestEnd, cut.end);
        }

        // Add some padding
        earliestStart = Math.max(0, earliestStart - 5);
        latestEnd = latestEnd + 5;

        // If the segment is small enough compared to the full video, pre-trim it
        const segmentDuration = latestEnd - earliestStart;
        if (segmentDuration < 300) { // Only pre-trim if segment is less than 5 minutes
            console.log(`Pre-trimming video to segment: ${earliestStart}s to ${latestEnd}s`);

            // Create a trimmed version of the input
            await ffmpeg.exec([
                '-i', 'input.mp4',
                '-ss', earliestStart.toString(),
                '-to', latestEnd.toString(),
                '-c', 'copy', // Use fast copy mode
                'trimmed_input.mp4'
            ]);

            // Verify the trimmed file exists before proceeding
            try {
                await ffmpeg.readFile('trimmed_input.mp4', { encoding: 'utf8' });

                // Replace the original input with the trimmed version
                await ffmpeg.unlink('input.mp4');
                await ffmpeg.rename('trimmed_input.mp4', 'input.mp4');

                console.log("Pre-trimming completed successfully");

                // Adjust all cut times to be relative to the new trimmed input
                for (const cut of cuts) {
                    cut.start = cut.start - earliestStart;
                    cut.end = cut.end - earliestStart;
                }
            } catch (readError) {
                console.warn("Trimmed file not created properly, using original input:", readError);
            }
        } else {
            console.log("Segment too large for pre-trimming, using full video");
        }
    } catch (error) {
        console.warn("Error during pre-trimming:", error);
        // Continue with the original input
    }
}

async function verifyAudioInFile(ffmpeg, inputFile) {
    try {
        let hasAudio = false;

        try {
            // Run FFprobe-like command to get file info
            const res = await ffmpeg.exec(["-i", inputFile, "-map", "0:a", "-f", "null", "-"])
            hasAudio = res === 0
        } catch (probeError) {
            console.error("Unexpected error during ffmpeg check:", probeError);
            throw probeError; // Re-throw unexpected errors
        }

        return hasAudio
    } catch (error) {
        console.error("Error verifying audio:", error);
        return false
    }
}