import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from "firebase/firestore";
import { head } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { Calendar } from "react-native-calendars";
import ClassModal from "../../components/ClassModal";
import DateFancyDisplayer from "../../components/calendarComponents/DateFancyDisplayer";
import Modal from "../../components/common/Modal";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import AdvanceContext from "../../context/AdvanceContext";
import getAllClasses from "../../utils/getAllClasses";
import swapIcon from "../../utils/swapIcon";


var checkRanges = function (start, end) {
	var compareDate = moment();
	var startDate = moment(start).subtract(1, "hour")
	var endDate = moment(end).add(1, "hour")
	const p = {
		isBeforeEnd: compareDate.isBefore(endDate),
		isOngoing: compareDate.isBetween(startDate, endDate),
	}
	return p
};


const CalendarScreen = () => {

	const { advances, courses } = useContext(AdvanceContext)
	const sc = useWindowDimensions()
	const isPhone = sc.width < 1000

	const [daySelected, setDaySelected] = useState(null)
	const [classDetails, setClassDetails] = useState(null)

	const { calendarClasses, allClasses, allClassesOriginal, nextContents } = getAllClasses(advances, courses)


	const exams = useMemo(() => nextContents.filter(el => el.contentType === "EXAM"), [nextContents])
	const tasks = useMemo(() => nextContents.filter(el => el.contentType !== "EXAM"), [nextContents])

	return (
		<>
			<ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingVertical: isPhone ? 20 : 30, paddingHorizontal: 15, maxWidth: 1200, width: "100%", alignSelf: "center" }}>
				{courses?.filter(el => el.status === "APPROVED")?.length > 0 ?
					<>
						<StyledText style={{ fontFamily: "title", fontSize: isPhone ? 35 : 45, textAlign: "right" }}>Mi Calendario</StyledText>

						<View style={[{ flex: sc.width < 1000 ? null : 1, height: "auto", width: "100%", marginTop: 20, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto" }]}>
							<Calendar
								// Initially visible month. Default = now
								// current={moment().format("YYYY-MM-DD")}
								onDayPress={day => {
									const todaysClasses = allClasses.filter(el => el.start === day.dateString) || []
									setDaySelected(todaysClasses)
								}}
								monthFormat={'yyyy MM'}
								renderArrow={direction => <StyledText>{direction === "left" ? "Anterior" : "Siguiente"}</StyledText>}
								firstDay={1}
								showWeekNumbers={false}
								onPressArrowLeft={subtractMonth => subtractMonth()}
								onPressArrowRight={addMonth => addMonth()}
								disableAllTouchEventsForDisabledDays={true}
								renderHeader={date => {
									return <StyledText>{moment(date[0]).format("MMMM YYYY")}</StyledText>
								}}
								enableSwipeMonths={true}
								markedDates={calendarClasses}
							/>
						</View>

						<View style={{ flexDirection: isPhone ? "column" : "row" }}>
							<View style={{ flex: isPhone ? null : 1, marginRight: isPhone ? 0 : 20 }}>
								<StyledText style={{ fontFamily: "Title", fontSize: isPhone ? 35 : 45, textAlign: isPhone ? "right" : "left", marginTop: 20 }}>Mis Próximas Tareas</StyledText>
								<View style={{ flexDirection: sc.width < 1000 ? "column" : "row" }}>

									<View style={{ flex: sc.width < 1000 ? null : 1, height: "auto" }}>
										<View style={{ width: "100%", marginTop: 20 }}>
											{tasks?.length > 0 ?
												<View style={[{ backgroundColor: "white", alignItems: "center", justifyContent: "center", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 7, height: "auto" }]}>
													{tasks.map((el, i) => {
														return <ContentCard content={el} key={i} />
													})}
												</View>
												:
												<View style={[{ backgroundColor: "white", alignItems: "center", justifyContent: "center", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 7, height: "auto" }]}>
													<Text style={{ fontSize: 24, fontFamily: "DemiBold", color: "darkgray", borderRadius: 7 }}>Sin Próximas Tareas</Text>
												</View>
											}
										</View>

									</View>

								</View>
							</View>
							<View style={{ flex: isPhone ? null : 1 }}>
								<StyledText style={{ fontFamily: "Title", fontSize: isPhone ? 35 : 45, textAlign: isPhone ? "right" : "left", marginTop: 20 }}>Mis Próximos Exámenes</StyledText>
								<View style={{ flexDirection: sc.width < 1000 ? "column" : "row" }}>
									<View style={{ flex: sc.width < 1000 ? null : 1, height: "auto" }}>
										<View style={{ width: "100%", marginTop: 20 }}>
											{exams?.length > 0 ?
												<View style={[{ backgroundColor: "white", alignItems: "center", justifyContent: "center", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 7, height: "auto" }]}>
													{exams.map((el, i) => {
														return <ContentCard content={el} key={i} />
													})}
												</View>
												:
												<View style={[{ backgroundColor: "white", alignItems: "center", justifyContent: "center", paddingHorizontal: 20, paddingVertical: 20, borderRadius: 7, height: "auto" }]}>
													<Text style={{ fontSize: 24, fontFamily: "DemiBold", color: "darkgray", borderRadius: 7 }}>Sin Próximas Examenes</Text>
												</View>
											}
										</View>

									</View>

								</View>
							</View>
						</View>
					</>
					:
					<>
						<StyledText style={{ fontFamily: "title", fontSize: 45, textAlign: "right" }}>Mi Calendario</StyledText>
						<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
							<StyledText style={{ fontSize: 22, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>No tienes Cursos Activos</StyledText>
						</View>
					</>
				}


			</ScrollView>


			{daySelected &&
				<Modal onClickOutside={() => setDaySelected(false)} modalContainerStyle={{ width: "95%", maxWidth: 600 }}>
					<View style={{ minWidth: 350, alignItems: "center", justifyContent: "center" }}>
						<View style={{ marginBottom: 15, width: "100%", flexDirection: "row", alignItems: "center" }}>
							<View style={{ flex: 1 }}>
								<StyledText style={{ fontFamily: "Title", textAlign: "left", fontSize: 34 }}>Clases</StyledText>
							</View>
							<TouchableOpacity style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }} onPress={() => setDaySelected(false)} >
								<Ionicons name="close" size={30} color="black" />
							</TouchableOpacity>
						</View>
						{(!daySelected || daySelected?.length === 0) ?
							<View style={{ paddingHorizontal: 10, paddingVertical: 25 }}>
								<StyledText style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 16, opacity: .5 }}>No hay clases programadas</StyledText>
							</View>
							:
							daySelected?.sort((a, b) => new Date(a.start) - new Date(b.start))?.map((content, i, arr) => {
								const { title, type, id } = content;
								const now = new Date();

								return (
									<TouchableOpacity
										accessibilityRole="button"
										// disabled={!previousSectionCompleted}
										key={i}
										onPress={() => {
											const d = allClassesOriginal.find(el => el.id === content.id)
											const dRef = allClasses.find(el => el.id === content.id)
											setClassDetails({ ...d, ref: dRef.ref, start: content.originalStart, end: content.originalEnd})
										}}
										style={[styles.shadow, {
											// opacity: previousSectionCompleted ? 1 : 0.4,
											width: "100%",
											backgroundColor: "white",
											borderRadius: 7,
											flexDirection: "row",
											padding: 10,
											marginBottom: arr?.length === i + 1 ? 0 : 10
										}]}
									>

										<DateFancyDisplayer date={content?.start} />

										<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center", paddingLeft: 15 }}>
											<StyledText style={{ fontSize: 20 }}>
												{title}
											</StyledText>
											<StyledText style={{ marginRight: 15, color: "grey", fontSize: 16, marginTop: 5, }}>
												{content.start && content?.startTime} - {content.end && content?.endTime}
											</StyledText>
										</View>

										{checkRanges(content.start, content.end)?.isOngoing ?
											<TouchableOpacity onPress={() => console.log("BARCODE")}>
												<Ionicons name="qr-code" size={30} color="black" />
											</TouchableOpacity>
											: null}

									</TouchableOpacity>
								)
							})}

					</View>
				</Modal>
			}

			{classDetails && <ClassModal data={classDetails} advanceRef={classDetails.ref} onClose={() => setClassDetails(false)} openCallback={undefined} />}
		</>
	);
};



 

const ContentCard = ({ content }) => {
	const db = getFirestore();
	const { title, type, id, ref, deadlines } = content;
	const navigation = useNavigation()

	const { advances } = useContext(AdvanceContext);

	const [gradedTask, setGradedTask] = useState(null);

	async function getGradedTask({ taskId, advances }) {
		let task = null;

		for await (const adv of advances) {
			const advContentsRef = query(collection(db, adv.ref.path, "contents"), where("contentId", "==", taskId), orderBy("createdAt", "desc"), limit(1));
			const advContents = await getDocs(advContentsRef);
			if (advContents.empty) continue;
			const found = head(advContents.docs);
			if (found) {
				task = found.data();
				break;
			}
		}

		return task;
	}

	useEffect(() => {
		getGradedTask({ taskId: id, advances }).then(task => task && setGradedTask(task))
	}, [id, advances])

	if (gradedTask && gradedTask?.status === "APPROVED") return null;

	return (
		<TouchableOpacity
			accessibilityRole="button"
			// disabled={!previousSectionCompleted}
			onPress={() => {
				navigation.navigate("Course", { id: content.courseId, path: content.path, content: content.content })
			}}
			// onPress={() => console.log({ ...content, index })}
			style={{
				// opacity: previousSectionCompleted ? 1 : 0.4,
				width: "100%",
				flexDirection: "row",
				marginBottom: 15
			}}
		>

			<View style={{ position: "relative", height: 80, width: 80, borderRadius: 40 }}>

				{content.image
					? <SecureImage
						accessibilityRole="none"
						style={{ borderRadius: 45, flex: 1 }}
						placeholder="https://pbs.twimg.com/profile_images/1527575245112098816/j7D73IDV_400x400.jpg"
						uri={content.image}
					/>
					: (
						<View style={{ position: "relative", height: 80, width: 80, borderRadius: 40, alignItems: "center", justifyContent: "center", backgroundColor: content.contentType === "EXAM" ? "#0b1831" : "#e4002a" }}>
							<Ionicons name={swapIcon(content.contentType).icon} size={35} color="white" />
						</View>
					)
				}
				{gradedTask && gradedTask?.status && gradedTask?.status !== "APPROVED" && <TaskStatusIcon status={gradedTask?.status} />}
			</View>
			<View accessibilityRole="none" style={{ flex: 1, justifyContent: "center", paddingHorizontal: 15 }}>
				<StyledText style={{ marginRight: 10, fontFamily: "DemiBold", fontSize: 16 }}>
					{title}
				</StyledText>
				{/* {console.log(deadlines)} */}
				{deadlines.length > 0 &&
					<View style={{ padding: 10, backgroundColor: "#f2f2f2", borderRadius: 7, marginTop: 10 }}>
						{deadlines.map((el, i) => {
							if (!el.start || !el.end) return null
							return (
								<StyledText key={i} style={{ fontSize: 14 }}>
									{`${el.name} - ${el.start ? moment(el?.start).format("DD-MM-YYYY HH:mm") : "-"} a ${el.end ? moment(el?.end).format("DD-MM-YYYY HH:mm") : "-"}`}
									{/* {section.contents.length > 0 && `${completed} de ${total} Completado`} */}
								</StyledText>
							)
						})}
					</View>
				}
				{/* <StyledText style={{ marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5, }}>
										{section.contents.length > 0 && `${completed} de ${total} Completado`}
									</StyledText> */}
			</View>
		</TouchableOpacity>
	)
}

export default CalendarScreen;

const TaskStatusIcon = ({ status }) => {


	return (
		<View style={{
			position: "absolute", right: 0, top: 0, height: 30, width: 30, alignItems: 'center', justifyContent: 'center', backgroundColor: "#f2c94c", borderRadius: 15,
		}} >
			{(status === "CHANGESREQUIRED" || status === "FAILED") && <Feather name="alert-triangle" size={18} color="black" style={{ marginBottom: 3 }} />}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		marginBottom: 60,
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.2,
		shadowRadius: 10.5,
		elevation: 10,
	},
	imageRedond: {
		height: 40,
		width: 40,
		borderRadius: 7,
		resizeMode: "cover",
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	topicBox: {
		width: 300,
		padding: 15,
		marginBottom: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderWidth: 1,
		borderRadius: 4,
		borderColor: "gainsboro",
	},
	slideIn: {
		0: {
			opacity: 0.3,
			scale: 0.7,
		},
		0.5: {
			opacity: 0.7,
			scale: 0.8,
		},
		1: {
			opacity: 1,
			scale: 1,
		},
	},
});