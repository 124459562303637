import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import { doc, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ActivityIndicator, Platform, ScrollView, StyleSheet, TouchableOpacity, View, useWindowDimensions } from "react-native";
import * as yup from "yup";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import MediaViewerManager from "../../components/common/MediaViewerManager";
import Modal from "../../components/common/Modal";
import StyledText from "../../components/common/StyledText";
import SelectField from "../../components/formComponents/SelectField";
import TextField from "../../components/formComponents/TextField";
import AuthContext from "../../context/AuthContext";
import useYupValidationResolver from "../../utils/useYupValidationResolver";

const tenants = [
	{
		"label": "REAL FEDERACION ESPAÑOLA DE FÚTBOL",
		"value": "OF4VYpRfgUJEH5rG2syJ"
	},
	{
		"label": "REAL FEDERACION ANDALUZA DE FÚTBOL",
		"value": "6HRTm9IePvg11ck8K8mZ"
	},
	{
		"label": "FEDERACION VIZCAINA DE FUTBOL",
		"value": "79g8HLLjWCrqfFTDUf7i"
	},
	{
		"label": "FEDERACION TERRITORIAL DE ARAGON DE FÚTBOL",
		"value": "7W6TSquf4c0CUmwHthTR"
	},
	{
		"label": "FED. DE FUTBOL DE LA C. VALENCIANA",
		"value": "8lkXGIQnhKT4hWA6lW0R"
	},
	{
		"label": "FEDERACION TERRIT. DE CASTILLA LA MANCHA FÚTBOL",
		"value": "9erd6xGIqMtUgX9TMA0Z"
	},
	{
		"label": "FED. TERRITORIAL NAVARRA DE FÚTBOL",
		"value": "9r7MwCJoby873ImLnX8p"
	},
	{
		"label": "FEDERACION GUIPUZCOANA DE FUTBOL",
		"value": "BMRzDiDRbHUjNiYwn81u"
	},
	{
		"label": "REAL FEDERACION TERRITORIAL GALLEGA DE FÚTBOL",
		"value": "Cg3yAoHoAFLEMaD56sDf"
	},
	{
		"label": "REAL FEDERACION DE FÚTBOL DE CEUTA",
		"value": "GzvcO0AICs5OZPpRXOsc"
	},
	{
		"label": "FEDERACIÓN CATALANA DE FÚTBOL",
		"value": "OOTGRhIf9vn1ujU2nqLA"
	},
	{
		"label": "FEDERACIÓN INTERINSULAR DE FÚTBOL DE LAS PALMAS",
		"value": "PhOpwo6FQ06wQorFnZC3"
	},
	{
		"label": "REAL FEDERACIÓN DE FÚTBOL DEL PRINCIPADO DE ASTURIAS",
		"value": "Pjr6SrvZcHea8Km0FurV"
	},
	{
		"label": "FEDERACIÓ DE FUTBOL DE LES ILLES BALEARS",
		"value": "Sv8o6LcbBZH59IeuNXky"
	},
	{
		"label": "FED. TERRITORIAL CANTABRA DE FÚTBOL",
		"value": "TlBxwgTfkbgWOfQyMSf5"
	},
	{
		"label": "FED. TERRIT. DE CASTILLA Y LEON DE FUTBOL",
		"value": "Yl6rFFT2xzam8i9a8M3W"
	},
	{
		"label": "FEDERACIÓN RIOJANA DE FÚTBOL",
		"value": "dTZl8Hif3FtWuQ6IhBuj"
	},
	{
		"label": "ESCUELA DE ENTRENADORES FEDERACIÓN DE FÚTBOL REGIÓN DE MURCIA",
		"value": "hILep5wqQRQZ093bvTn2"
	},
	{
		"label": "FEDERACION TINERFEÑA DE FUTBOL",
		"value": "iyHsTMmGqQZtIFuHINTE"
	},
	{
		"label": "FEDERACION EXTREMEÑA DE FÚTBOL",
		"value": "kfrIWZBXpNbaIOpRw3Q0"
	},
	{
		"label": "FEDERACION ALAVESA DE FUTBOL",
		"value": "lIdM7sdVP9KTi4N8QwnD"
	},
	{
		"label": "FEDERACION MELILLENSE DE FÚTBOL",
		"value": "qRW8fEDROj8tIgvCdvEr"
	},
	{
		"label": "Real Federación de Fútbol de Madrid",
		"value": "soUmAGlEt2o5OXJYwrTQ"
	},
]


const Contact = () => {
	const app = getApp()
	const screen = useWindowDimensions()
	const functions = getFunctions(app, "europe-west1");
	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);



	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [termsModal, setTermsModal] = useState(false)
	const { customization } = useContext(AuthContext)

	const db = getFirestore()
	// const tenantOptRef = doc(db, '/tenants/OF4VYpRfgUJEH5rG2syJ/selectors/F1rr4R0NgQLf22NkA520')
	const docRef = doc(db, `globalPages/lnDtyT3wuH2CHkOsRCPc`)
	// const tenantsRef = collection(db, `tenants`)

	// const { data: tenantOptions, isLoading: isLoadingCourse } = useFirestoreDocumentData(["tenants"], tenantOptRef);
	const { data, isLoading, error: errorLoadingLOPD } = useFirestoreDocumentData(["globalPage", { slug: "proteccion-datos" }], docRef, {}, {});
	// const { data: tenants } = useFirestoreQuery(["tenants", "data"], tenantsRef, {}, {
	// 	select: x => x?.docs?.map(x => ({...x.data(), id: x.id}))
	// });

	const faqDocRef = doc(db, "config/faq");
	const { data: faqsData, isLoading: isLoadingFaq } = useFirestoreDocumentData(["faq"], faqDocRef);


	const schema = yup.object().shape({
		name: yup.string().required("Este campo es obligatorio"),
		email: yup.string().email("El email no es válido").required("Este campo es obligatorio"),
		message: yup.string().required("Este campo es obligatorio"),
		subject: yup.string().required("Este campo es obligatorio"),
		privacyPolicy: yup.boolean().oneOf([true], "Debes aceptar la política de privacidad"),
		privacyData: yup.boolean().oneOf([true], "Debes aceptar la política de protección de datos"),
		tenant: yup.string().required("Seleccione una de las Federaciones"),
		lopd: yup.boolean().oneOf([true], "Debe aceptar los terminos y condiciones").required("Debe aceptar los terminos y condiciones"),
		category: yup.string().oneOf(["Escuela de Entrenadores", "Licencias", "Incidencias en la plataforma", "Otros"], "Selecciona una categoría").required("Este campo es obligatorio"),
	})

	const resolver = useYupValidationResolver(schema)

	const {
		control,
		handleSubmit,
		reset,
		watch,
		trigger,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: "onChange",
		defaultValues: {
			name: "",
			email: "",
			subject: "",
			category: "",
			message: "",
			tenant: "",
			// privacyPolicy: false,
			// privacyData : false,
			lopd: false,

		},
	})

	const sendForm = async (values) => {
		const tenantName = (tenants?.find(v => v.value === values.tenant)?.label) || ""
		setLoading(true)

		const submitContactForm = httpsCallable(functions, "submitContactForm", {
			region: "europe-west1",
		})

		submitContactForm({ ...values, tenantName }).then(res => {
			setSuccess(true)
			setLoading(false)
		}).catch(err => {
			setError(true)
			setLoading(false)
		})
	}

	const resetState = () => {
		setSuccess(false)
		setError(false)
		reset({
			name: "",
			email: "",
			subject: "",
			category: "",
			message: "",
			tenant: "",
			lopd: false
		})
	}

	const lopd = watch("lopd")

	return (
		<>
			{/* FIN OTROS CURSOS */}
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				{/* SLIDER TOP */}
				<View style={{ height: 150, backgroundColor: customization.mainColor, borderBottomWidth: 1, borderBottomColor: "white" }}>
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<StyledText style={{ fontSize: 90, color: "white", fontFamily: "Title" }}>
							Contacto
						</StyledText>
					</View>
				</View>
				<View style={{ height: 50, width: "100%", backgroundColor: "#06162D" }} />

				{/* OTROS CURSOS */}
				<View style={{ paddingVertical: 15, minHeight: 500, maxWidth: 1000, alignSelf: "center", width: "100%", paddingHorizontal: 20, flexGrow: 1 }}>
					{isLoadingFaq ?
						<View style={{ height: 180, alignItems: "center", justifyContent: "center" }}>
							<ActivityIndicator color={customization.mainColor} />
						</View>
						:
						(faqsData?.faqs || [])?.map((category, i) => {
							return (
								<FaqSection key={i} data={category.questions} category={category.name} customization={customization} />
							)
						})}
				</View>


				{/* OTROS CURSOS */}
				<View style={{ paddingVertical: 50, alignItems: "center", justifyContent: "flex-start", flexGrow: 1, paddingHorizontal: 20 }}>

					{!success ?
						<View style={{ width: "100%", maxWidth: 800, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "flex-start", zIndex: 2 }}>
							<StyledText style={{ marginBottom: 20, fontSize: 16 }}>Si tienes alguna consulta que hacernos o necesitas ayuda con algo, no dudes en contactarnos rellenando el siguiente formulario y te responderemos lo antes posible.</StyledText>
							<View style={{ width: "100%" }}>
								<TextField error={errors["email"]} name={"email"} key="email" label="Email" placeholder="Email..." control={control} />
							</View>
							<View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
								<TextField error={errors["name"]} name={"name"} key="name" label="Nombre Completo" placeholder="Nombre Completo..." control={control} />

							</View>
							<View style={{ width: "100%", marginTop: 10 }}>
								<SelectField
									label="Categoría"
									control={control}
									error={errors["category"]}
									name="category"

									options={[
										"Escuela de Entrenadores",
										"Licencias",
										"Incidencias en la plataforma",
										"Otros"
									]}
								/>
							</View>
							<View style={{ width: "100%", marginTop: 10 }}>
								<SelectField
									label="Federación"
									control={control}
									error={errors["tenant"]}
									valueKey="value"
									labelKey="label"
									name="tenant"
									options={tenants}
								/>
							</View>
							<View style={{ width: "100%", marginTop: 10 }}>
								<TextField error={errors["subject"]} name={"subject"} key="subject" label="Asunto" placeholder="Asunto..." control={control} />
							</View>


							<View style={{ width: "100%", marginTop: 10 }}>
								<TextField
									numberOfLines={10}
									multiline={Platform.OS === "web" ? "true" : true}
									style={{ minHeight: 150, padding: 15, resize: "vertical" }}
									resize="vertical"
									alignItems="flex-start"
									error={errors["message"]}
									name={"message"}
									key="message"
									label="Mensaje"
									placeholder="Mensaje..."
									control={control} />
							</View>
							<View style={{ width: "100%", marginTop: 20 }}>
								<TouchableOpacity onPress={() => {
									if (lopd) {
										setValue("lopd", false)
										trigger("lopd")
									} else {
										setTermsModal(true)
									}
								}
								} style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
									<View style={{ marginRight: 10, borderWidth: 3, borderColor: "black", borderRadius: 4, width: 30, height: 30, alignItems: "center", justifyContent: "center" }}>
										{lopd && <FontAwesome name={"check"} size={18} color={"black"} />}
									</View>
									<View style={{ flex: 1 }}>
										<StyledText style={{ color: "black", fontSize: 16 }}>Declaro que he leído y acepto los términos y condiciones</StyledText>
									</View>
								</TouchableOpacity>
								{errors?.lopd && (
									<StyledText style={{ marginTop: 10, fontSize: 12, color: "red" }}>
										{errors?.lopd?.message}
									</StyledText>
								)}
								{/* <CheckBoxField checkboxColor="white" labelColor="white" error={errors["lopd"]} name={"lopd"} key="lopd" label="Al registrarme acepto los terminos y condiciones" control={control} /> */}
							</View>

							{termsModal ? <Modal padding={0} modalContainerStyle={{ maxHeight: screen?.height - 100, maxWidth: 550, width: "95%", borderRadius: 10 }}>

								<ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 15 }}>
									<StyledText style={{ fontSize: 34, fontFamily: "Title", color: "black", textAlign: "center" }}>Terminos y Condiciones</StyledText>
									{!errorLoadingLOPD ?
										<div dangerouslySetInnerHTML={{ __html: data?.content || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 16, color: "#303030" }} />
										:
										<View style={{ alignItems: "center" }}>
											<StyledText>Ocurrió un error cargando los terminos y condiciones, para acceder a ellos siga el siguiente enlace</StyledText>
											<Button color={"#E4002B"} label="Finalizar" height="35px" round="7px" onPress={() => {
												window.open("https://onformacion.rfef.es/proteccion-datos", "_blank")
											}} />
										</View>
									}
								</ScrollView>
								<View style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
									<Button color={"#f2f2f2"} label="Rechazar" height="35px" round="7px" onPress={() => {
										trigger("lopd")
										setValue("lopd", false)
										setTermsModal(false)
									}} />
									<Button color={"#E4002B"} label="Aceptar" height="35px" round="7px" onPress={() => {
										setValue("lopd", true)
										trigger("lopd")
										setTermsModal(false)
									}} />

								</View>

							</Modal > : null}



							<View style={{ marginTop: 25, width: "100%", alignItems: "flex-start" }}>
								<Button height="35px" round={7} onPress={handleSubmit(sendForm)} loading={loading} label="Enviar" />
							</View>

						</View>
						:
						error ?
							<View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "flex-start", zIndex: 2 }}>
								<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
									<Ionicons name="close-circle-outline" color={"red"} size={120} />
									<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Ocurrió un error al enviar el mensaje</StyledText>

									<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Disculpa las molestias, vuelva a intentarlo
									</StyledText>
									<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
										<Button height="35px" onPress={() => setError(false)} label="Volver" round={7} />
									</View>
								</View>

							</View>
							:
							<View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "flex-start", zIndex: 2 }}>
								<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
									<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
									<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tu mensaje se ha enviado con éxito!</StyledText>

									<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Te responderemos con la mayor brevedad posible
									</StyledText>
									<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
										<Button height="35px" onPress={resetState} label="Volver" round={7} />
									</View>
								</View>

							</View>
					}


				</View>
				{/* FIN OTROS CURSOS */}


				<Footer />
			</ScrollView>
		</>
	);
};

export default Contact;

// const styles = StyleSheet.create({
// 	wrapper: {},
// 	slide1: {
// 		flex: 1,
// 	},
// 	slide2: {
// 		flex: 1,
// 		justifyContent: "center",
// 		alignItems: "center",
// 		backgroundColor: "#97CAE5",
// 	},
// 	slide3: {
// 		flex: 1,
// 		justifyContent: "center",
// 		alignItems: "center",
// 		backgroundColor: "#92BBD9",
// 	},
// 	text: {
// 		color: "#fff",
// 		fontSize: 30,
// 		fontWeight: "bold",
// 	},
// 	shadow: {
// 		shadowColor: "rgba(0, 0, 0, 1)",
// 		shadowOffset: { width: 0, height: 7 },
// 		shadowOpacity: 0.1,
// 		shadowRadius: 10,
// 		elevation: 10,
// 	},
// });


const FaqSection = ({ data, category, customization }) => {
	return (
		<View style={{}}>
			<View style={{ borderRadius: 7, width: "100%", paddingVertical: 10, marginBottom: 10, flexDirection: "row" }}>
				<StyledText style={{ marginLeft: 5, fontSize: 40, color: customization.mainColor, fontFamily: "Title" }}>{category}</StyledText>
			</View>
			{data.length === 0 ?
				<View style={{ borderRadius: 5, backgroundColor: "#f2f2f2", padding: 20 }}>
					<StyledText style={{ fontSize: 16, textAlign: "center", color: "gray" }}>No hay preguntas en esta categoría</StyledText>
				</View>
				:
				data?.map((item, i) => {
					return (
						<Question key={i} data={item} customization={customization} />
					)
				})}
		</View>
	)
}

const Question = ({ data, customization }) => {
	const [open, setOpen] = useState(false)
	return (
		<View style={[styles.shadow, { marginBottom: 10, borderRadius: 7 }]}>
			<TouchableOpacity onPress={() => setOpen(!open)} style={{ borderRadius: 7, width: "100%", paddingVertical: 10, paddingHorizontal: 15, flexDirection: "row" }}>
				<View style={{ flex: 1 }}>

					<StyledText style={{ marginLeft: 5, fontSize: 18, fontFamily: "TitleWide" }}>{data.question}</StyledText>

				</View>
				<View>
					<Ionicons name={open ? "remove" : "add"} size={30} color={customization.mainColor} />
				</View>
			</TouchableOpacity>
			<div style={{ fontFamily: "Regular", padding: "0px 20px", overflow: "hidden", height: open ? "auto" : 0 }} dangerouslySetInnerHTML={{ __html: data.answer }} />
			{data?.media &&
				<div style={{ padding: "0px 20px", overflow: "hidden", height: open ? "auto" : 0 }}>
					<MediaViewerManager
						type={"VIDEO"}
						url={data?.media}
						cover={""}
						tracking={false}
						style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", height: "auto", maxHeight: 400 }}
						// videoProps={{
						// 	// useNativeControls: false,
						// 	positionCallback: () => null,
						// 	onPlaybackStatusUpdate: () => null
						// 	// positionMillis: lastWatched,
						// }}
					/>
					{/* <SecureVideo uri={data?.media} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", height:"auto", maxHeight: 400 }} /> */}
				</div>
			}
		</View>
	)
}

const styles = StyleSheet.create({
	wrapper: {},
	slide1: {
		flex: 1,
	},
	slide2: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#97CAE5",
	},
	slide3: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#92BBD9",
	},
	text: {
		color: "#fff",
		fontSize: 30,
		fontWeight: "bold",
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 7 },
		shadowOpacity: 0.1,
		shadowRadius: 10,
		elevation: 10,
	},
});
