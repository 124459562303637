import { useNavigation, useRoute } from "@react-navigation/native";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import * as Localization from "expo-localization";
import { doc, getFirestore } from "firebase/firestore";
import { I18n } from "i18n-js";
import { get, head } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, TextInput, TouchableOpacity, View, useWindowDimensions } from "react-native";
import PaymentCompletedModal from "../../components/PaymentCompletedModal";
import PaymentsModal from "../../components/PaymentsModal";
import Button from "../../components/common/Button";
import StyledText from "../../components/common/StyledText";
import EditDataModal from "../../components/registerComponents/EditModal";
import CourseHorizontalCard from "../../components/smartComponents/CourseHorizontalCard";
import AdvanceContext from "../../context/AdvanceContext";
import AuthContext from "../../context/AuthContext";
import useLastDebtWarning from "../../hooks/useLastDebtWarning";
import translations from "../../locale/translations";

const i18n = new I18n(translations)
i18n.locale = Localization.locale;
i18n.enableFallback = true;


const SmartHome = () => {
	const params = useRoute()?.params
	const { userData, customization } = useContext(AuthContext);
	const { advances, courses, payments, isLoading } = useContext(AdvanceContext)
	const navigation = useNavigation();

	const [paymentCompleted, setPaymentCompleted] = useState(false)
	const [paymentError, setPaymentError] = useState(false)
	const [courseSelected, setCourseSelected] = useState(null)
	const [paymentsModal, setPaymentsModal] = useState(false)
	const [search, setSearch] = useState("")
	const [editData, setEditData] = useState(null)
	const sc = useWindowDimensions()
	const isPhone = sc.width < 1000


	const db = getFirestore()

	const [tab, setTab] = useState(0)

	const getAdvance = (course) => {
		const advance = advances.find(el => el?.courseRef?.id === course?.id)
		const completed = advance?.advance?.filter(el => el?.completed)?.length
		const total = course?.contentDetails?.reduce((acc, cur) => acc + cur.contents.length, 0)
		return { advance, completed: completed, total: total, percentage: Math.round(completed / total * 100) }
	}

	const changeTab = (n) => {
		setTab(n)
		setCourseSelected(null)
	}

	const userPaymentRef = params?.order ? doc(db, `payments/${params?.order}`) : null;

	const { data, isSuccess } = useFirestoreDocumentData(["userPayment", params?.order], userPaymentRef, { subscribe: true }, {
		enabled: !!params?.order,
		onError: e => console.log(e)
	});

	useEffect(() => {
		if (isSuccess && data && params?.order) {
			if (data && data.status === "PAID") {
				setPaymentCompleted(true)
			}

			if (data.status === "ERROR") {
				setPaymentError(true)
			}
		}
	}, [isSuccess, data, params?.order])

	const upToDatePayments = useLastDebtWarning({ courses, payments })

	// useEffect(() => {
	// 	let flag = false
	// 	const upToDatePayments = Object.fromEntries(courses.map(c => {
	// 		const thisPayment = payments.find(p => p.course?.id === c.id)
	// 		const discountAmount = thisPayment?.discountAmount || 0
	// 		let remainingDiscount = discountAmount
	// 		const discountedTerms = (c?.payment || [])
	// 			.filter(p => p.active).sort((a, b) => moment(a.endDate.toDate() || 0).isBefore(moment(b.endDate.toDate() || 0)) ? 1 : -1)
	// 			.map(p => {
	// 				if (remainingDiscount > 0) {
	// 					if (remainingDiscount > p.amount) {
	// 						remainingDiscount -= p.amount
	// 						return { ...p, amount: 0 }
	// 					} else {
	// 						const remainingAmount = p.amount - remainingDiscount
	// 						remainingDiscount = 0
	// 						return { ...p, amount: remainingAmount }
	// 					}
	// 				} else { return p }
	// 			})
	// 			.reverse()
	// 		const pastTerms = discountedTerms.filter(p => p.active && moment(p.endDate.toDate()).isBefore(moment()))
	// 		const shouldHavePaid = pastTerms.reduce((acc, it) => acc + (it?.amount) || 0, 0)
	// 		const havePaid = (thisPayment?.payments || []).filter(p => !p.preinscriptionPayment).reduce((acc, it) => acc + it.amount, 0)
	// 		if (havePaid < shouldHavePaid) {
	// 			flag = true
	// 		}
	// 		return [c.id, {
	// 			name: c.name,
	// 			upToDate: havePaid >= shouldHavePaid
	// 		}]
	// 	}))
	// 	if (flag) {
	// 		const lastDebtWarning = moment(localStorage.getItem("lastDebtWarning"))
	// 		const diff = moment().diff(lastDebtWarning)
	// 		const arr = Object.values(upToDatePayments).filter(p => !p.upToDate)
	// 		if (!lastDebtWarning || diff > (24 * 60 * 60 * 1000)) {
	// 			setNotUpToDateModal({
	// 				show: true,
	// 				notUpToDate: `${arr.length > 1 ? " los" : "l"} curso${arr.length > 1 ? "s" : ""}${arr.reduce((acc, it, i) => acc + (i + 1 === arr.length ? " y " : ", ") + (it.name || ""), "").slice(1)}`
	// 			})
	// 			localStorage.setItem("lastDebtWarning", new Date().toISOString())
	// 		}
	// 	}
	// 	setUpToDatePayments(upToDatePayments)
	// }, [advances, courses, payments]);

	const normalizeString = (str) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
	}

	const manageCourseFilter = (el, tab) => {
		// console.log(el?.name, el?.tag)
		if (tab === 0) {
			return el?.advance?.status === "APPROVED" && el?.endDate > new Date()
		} else if (tab === 1) {
			return (el?.advance?.status !== "APPROVED" && el?.advance?.status !== "REJECTED") && el?.endDate > new Date()
		} else if (tab === 2) {
			return el?.advance?.status === "REJECTED" || el?.endDate < new Date()
		} else {
			return false
		}
	}

	const searchFilter = (el) => (normalizeString(el?.name).includes(normalizeString(search)) || normalizeString(el?.tag).includes(normalizeString(search)))



	if (isLoading) {
		return (
			<View style={{ height: "100%", width: "100%", padding: 100, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" color="red" />
			</View>
		)
	}
	return (
		<>
			<ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingVertical: isPhone ? 20 : 30, paddingHorizontal: 15, maxWidth: 1200, width: "100%", alignSelf: "center" }}>
				{(courses === undefined || courses?.length === 0) ?
					<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
						<StyledText style={{ fontSize: 20, fontWeight: "bold", marginTop: 20, textAlign: "center" }}>{i18n.t("No Courses Available")}</StyledText>
						<Button label="Ver el Catálogo" style={{ marginTop: 20 }} round={7} onPress={() => navigation.navigate("StackPublic", { screen: "HomeScreen" })} />
					</View>
					:
					<>
						<View style={{ flexDirection: "row", flexWrap: "wrap", gap: 15, marginTop: isPhone ? -10 : 0, justifyContent: "flex-end" }}>
							<TextInput value={search} onChangeText={ev => setSearch(ev)} style={{ borderWidth: 1, borderColor: "gainsboro", flex: 1, minWidth: 250, borderRadius: 5, backgroundColor: "white", paddingHorizontal: 15, alignItems: "center", height: 45 }} placeholder="Buscar curso por nombre..." />
							<TouchableOpacity style={{ height: 50, alignItems: "center", justifyContent: "center" }} onPress={() => changeTab(0)}>
								<StyledText style={{ fontFamily: "title", fontSize: 34, color: tab === 0 ? customization.mainColor : "black" }}>Cursando ({courses?.filter(el => searchFilter(el) && manageCourseFilter(el, 0))?.length || 0})</StyledText>
							</TouchableOpacity>
							<TouchableOpacity style={{ height: 50, alignItems: "center", justifyContent: "center" }} onPress={() => changeTab(1)}>
								<StyledText style={{ fontFamily: "title", fontSize: 34, color: tab === 1 ? customization.mainColor : "black" }}>Pendientes ({courses?.filter(el => searchFilter(el) && manageCourseFilter(el, 1))?.length || 0})</StyledText>
							</TouchableOpacity>
							<TouchableOpacity style={{ height: 50, alignItems: "center", justifyContent: "center" }} onPress={() => changeTab(2)}>
								<StyledText style={{ fontFamily: "title", fontSize: 34, color: tab === 2 ? customization.mainColor : "black" }}>Histórico ({courses?.filter(el => searchFilter(el) && manageCourseFilter(el, 2))?.length || 0})</StyledText>
							</TouchableOpacity>

						</View>
						<View style={{ flexDirection: "row" }}>

							<View style={{ width: (courseSelected !== null && courses?.length > 0 && sc.width > 1000) ? "60%" : "100%", paddingTop: 20 }}>
								{!courses || courses?.filter((el) => searchFilter(el) && manageCourseFilter(el, tab))?.length === 0 ?
									<View style={{ height: 300, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
										<StyledText style={{ fontSize: 20, fontWeight: "bold", marginTop: 20, textAlign: "center" }}>{tab === 1 ? i18n.t("No Courses Pending Review") : tab === 2 ? i18n.t("No Courses Completed") : tab === 0 ? i18n.t("No Courses Available") : i18n.t("No Courses Rejected")}</StyledText>
									</View>
									:
									courses?.filter((el) => searchFilter(el) && manageCourseFilter(el, tab))?.sort((a, b) => b.initDate - a.endDate).map((el, i) => {

										const { advance } = getAdvance(el)
										const payment = payments?.find(p => p.course?.id === el.id)
										const firstPayment = head(get(payment, "payments", []))

										return (
											<CourseHorizontalCard
												key={i}
												el={el}
												course={el}
												disabled={tab === 1 || tab === 3 || (tab === 2 && el.advance.status !== "APPROVED")}
												firstPayment={firstPayment}
												coursePayment={payment}
												paymentCallback={() => setPaymentsModal(el)}
												changeDataCallback={() => setEditData({ ...advance, course: el })}
												onPress={() => {
													el?.advance?.visible === false ?
														alert("El administrador ha bloqueado el acceso a este curso")
														:
														navigation.navigate("Course", { id: el.id })
												}}
												paymentWarning={!(upToDatePayments[el.id]?.upToDate)}
											/>
										)
									})
								}
							</View>
						</View>
					</>
				}
			</ScrollView>
			
			{paymentCompleted ? <PaymentCompletedModal paymentError={paymentError} closeCallback={() => setPaymentCompleted(false)} /> : null}
			{editData && <EditDataModal setOpen={setEditData} course={editData} courseId={editData.courseId} preinscriptionData={get(editData.course, "preinscriptionData", [])} />}
			{paymentsModal && <PaymentsModal open={paymentsModal !== null} setOpen={setPaymentsModal} course={paymentsModal} courseId={paymentsModal.id} userData={userData} tenantId={paymentsModal.tenantId} originUrl={"/alumno/cursos/"} />}

		</>
	);
};

export default SmartHome;




